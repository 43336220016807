import logo from './back.png';
import data from './crossword_data';
import { Link } from "react-router-dom";

function Puzzle(props) {
  const {id} = props.match.params

  document.title = "Puzzle: " + data[id]['puzzleName'];

  return (
    <div className="App-left">
      <Link to={`/`} className="App-link"><img src={logo} width="10" height="10"/>&nbsp;&nbsp;<span>Back to home</span>&nbsp;&nbsp;</Link><br/><br/>
      <iframe 
        height="100%" 
        width="100%" 
        allowfullscreen="true" 
        styles="border:none;width: 100% !important;position: static;display: block !important;margin: 0 !important;" 
        name="25a809e1700b12c75c41802e3a95d536f486a4ba724d6ac26219986199e6c832" 
        src={data[id]['src']}
      ></iframe>
    </div>
  );
}

export default Puzzle;
