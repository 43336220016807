import logo from './back.png';
import { Link } from "react-router-dom";

function Puzzle(props) {
  document.title = "Contribute";

  return (
    <div className="App-left">
      <header className="App-header">
      <Link to={`/`} className="App-link"><img src={logo} width="10" height="10"/>&nbsp;&nbsp;<span>Back to home</span>&nbsp;&nbsp;</Link><br/><br/>
        <h2>Contribute</h2>
      </header>
      <body className="body-special">
        If you want to try your hand at creating your own Singapore-based crossword to be published here, you may send your submissions to <a href="mailto:perniciousperil@gmail.com" className="App-link">perniciousperil@gmail.com</a>.
        This is a fairly casual project, so feel free to flex your creativity with the design and submit in what file format you prefer, as long as it is readable and understandable. <br/><br/>
        That said, to maintain a certain level of puzzle quality, standard crossword conventions should be adhered to - ie. a symmetrical grid, answers having a minimum of 3 letters, black squares used sparingly, and accessible clues. If you need any help creating, you can also contact the same email.<br/><br/>
        
        For those completely new, NYT has an 
        <a href="https://www.nytimes.com/2018/09/14/crosswords/how-to-make-a-crossword-puzzle-the-series.html" className="App-link"> excellent article </a>
        on the core concepts of crossword-making and tips. The free tool <a href="http://www.keiranking.com/apps/phil/" className="App-link">Phil</a> can be used to create the grids.
         <br/>
      </body>
    </div>
  );
}

export default Puzzle;
