import logo from './icon.png';
import './App.css';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'react-simple-card';
import { Component } from 'react';

class CardComponent extends Component {
  render() {
  return <Card>
      <Link to={`puzzle/` + this.props.id} className="App-dead-link">
        <div id='box'>
          <div class="item"><img src={logo} className="App-logo" alt="logo" /></div>
          <div class="item"><CardBody><b>{this.props.date}</b><br/>{this.props.name}</CardBody></div>
        </div>
      </Link>
    </Card>
  }
}

function App() {
  document.title = "Strait Across";

  return (
    <div className="App">
      <header className="App-header">
        <h2>Strait Across</h2>
        Some Singapore-based crosswords<br/>
        <br/>
        <CardComponent id="13" date="5th May 2024" name="Regular Service"/>
        <CardComponent id="12" date="21st July 2023" name="Different Frequency"/>
        <CardComponent id="11" date="28th April 2023" name="Crossing Lines"/>
        <CardComponent id="10" date="5th August 2022" name="Astral Projection"/>
        <CardComponent id="9" date="1st July 2022" name="Themeless #2"/>
        <CardComponent id="8" date="12th April 2022" name="Themeless #1"/>
        <CardComponent id="7" date="28th January 2022" name="Zodiac Race"/>
        <CardComponent id="6" date="19th November 2021" name="On the Rocks"/>
        <CardComponent id="5" date="29th October 2021" name="Fashion Show"/>
        <CardComponent id="4" date="22nd October 2021" name="Ping"/>
        <CardComponent id="3" date="15th October 2021" name="Startup Expansion"/>
        <CardComponent id="2" date="8th October 2021" name="Transmutation"/>
        <CardComponent id="1" date="1st October 2021" name="Singapore Pools"/>
      </header>
      <body>
        <a href="https://t.me/sgcrossword" className="App-link">Join telegram channel for updates</a><br/>
        <Link to='contribute/' className="App-link">Become a contributor</Link><br/>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdTeS8UhYXuogn3yclJd0R9TVAtSbUJJTxCMFg8FNFjKVTECg/viewform?usp=sf_link" className="App-link">Leave feedback</a><br/>
      </body>
    </div>
  );
}

export default App;
